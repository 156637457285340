<template>
  <div>
    <WrapperSubHeader>
      <span>{{ $t("users.users") }} <span class="font-normal text-base" v-if="!loading">/ (#{{ user.id }}) {{ user.name }}</span></span>
    </WrapperSubHeader>

    <div class="w-full my-5 mt-5">
      <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col">
        <router-link :to="{name: 'Admin Users'}" class="btn btn-ghost shadow-none mb-5 bg-gray-200 hover:bg-gray-300">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Volver
        </router-link>
        <SpinnerGeneral v-if="loading" />
        <TransitionFade>
          <div class="bg-white shadow overflow-hidden mt-5 sm:rounded-lg" v-if="!loading">
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t("users.info") }}
              </h3>
            </div>
            <div class="border-t border-gray-200">
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    {{ $t("users.name") }}
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ user.name }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    {{ $t("users.email") }}
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ user.email }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperSubHeader from "@/components/WrapperSubHeader"
import SpinnerGeneral from "@/components/SpinnerGeneral"
import TransitionFade from "@/components/TransitionFade"
import { useI18n } from 'vue-i18n'

export default {
  name: 'UserShow',
  props: ['id'],
  components: {
    WrapperSubHeader,
    TransitionFade,
    SpinnerGeneral,
  },

  data() {
    const { t, locale } = useI18n()
    return {
      t,
      locale,
      loading: false,
      user: {}
    };
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('AdminUsers/show', this.id).then(response => {
      if (response.status == 500) {
        this.$toast.error("Ocurrió un error, por favor reintente más tarde");
      }
      if (response.status == 200) {
        this.user = response.data.data
      }
      this.loading = false
    })
  },

  methods: {
  },
}
</script>
